import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import '../App.css';

import author from '../Images/author.png';

import back_icon from '../Images/back-icon.svg';
import apple_icon from '../Images/apple-icon.svg';
import user_icon from '../Images/user-icon.svg';
import code_icon from '../Images/code-icon.svg';
import pencil_icon from '../Images/pencil-icon.svg';
import map_icon from '../Images/map-icon.svg';
import brain_icon from '../Images/brain-icon.svg';
import steps_icon from '../Images/steps-icon.svg';
import puzzle_icon from '../Images/puzzle-icon.svg';
import warning_icon from '../Images/warning-icon.svg';
import check_icon from '../Images/check-icon.svg';
import hammer_icon from '../Images/hammer-icon.svg';
import gun_icon from '../Images/gun-icon.svg';
import bomb_icon from '../Images/bomb-icon.svg';
import teleporter_icon from '../Images/teleporter-icon.svg';

import Footer from './Footer.js';

import projectbl from '../Images/project-bl.jpg';
import sprites from '../Images/bl-sprites.jpg';
import marketing from '../Images/bajookieland-marketing.jpg';
import unity from '../Images/bl-unity.jpg';
import screenflow from '../Images/bl-screenflow.jpg';
import sketch from '../Images/bl-sketch.jpg';
import pause from '../Images/bajookieland-pause.jpg';
import purchase from '../Images/bajookieland-purchase.jpg';
import bomb from '../Images/bajookieland-bomb.jpg';
import vid from '../Images/bajookieland-vid.mp4'; 
import style_guide from '../Images/bajookieland-style-guide.jpg'; 

class ProjectBajookieland extends Component {
  
    constructor(props) {
        super();
        this.props = props;
    }
  
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount(){

  }

  visitWebsite() {
    window.location.href = "https://apps.apple.com/us/app/bajookieland/id6479661549";
  }

  render () {
    
    const footerYear = new Date().getUTCFullYear();

    return (
        
      <div id="project">
        
        <div className="nav">
          <Link to="/" class="link"><div className="back-container">
            <img className="back-icon" src={back_icon} alt=""></img>
            <p className="back-text">Back</p>
          </div></Link>
          <p className="nav-title"><Link to="/" class="link-nav-title">Brandon Falcone</Link></p>
          <Link class="link-disabled" style={{opacity:"0"}} ><div className="back-container">
            <img className="back-icon" src={back_icon} alt=""></img>
            <p className="back-text">Back</p>
          </div></Link>
        </div>
        <img className="project-image" src={projectbl} alt=""></img>

        <div className="project-content">
          <p className="project-title-large">Bajookieland: A Puzzle Platforming Game For iOS</p>
          <div className="button-container">
            <p className="project-bold-text">Play the game:</p>
            <div className="button" onClick={(e) => {this.visitWebsite()}}>
              <img className="button-icon" src={apple_icon} alt=""></img>
              <p>Download on the App Store</p>
            </div>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">My Tasks</p>

            <div className="icon-bullet">
              <img className="bullet-icon" src={user_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Video Game UX Design</p>
                <p className="project-subtext">I designed the tutorial, purchasing flow, and screen architecture.</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={code_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Programming</p>
                <p className="project-subtext">I developed the game in C# and Unity for the iOS platforms.</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={pencil_icon} alt=""></img>
              <div>
                <p className="project-bold-text">UI and Sprite Artwork</p>
                <p className="project-subtext">I created all sprite image assets and user interface components.</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={map_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Level Design</p>
                <p className="project-subtext">I constructed aesthetically pleasing levels with progressively increasing difficulty.</p>
              </div>
            </div>
          </div>

          <div className="project-chunk">
            <p className="project-text-medium">Gameplay Video</p>

            <div className="mobileVideoContainer">
              <video className="mobileVideo" muted="true" autoplay="true" loop="true" playsInline="true" webkit-playsinline>
                  <source src={vid} type="video/mp4" />
-               </video>
              </div>
          </div>

          <div className="project-chunk">
            <p className="project-text-medium">Unity Development</p>
            <p className="project-paragraph">I developed the game using Unity, exported the project to XCode, established an Apple Developer Account, conducted testing on Test Flight, and subsequently launched the game on the App Store.</p>
            <div className="trio-container">
              <div className="trio-item">
                <p className="project-bold-text">Programming Language</p>
                <p className="project-subtext">C#</p>
              </div>
              <div className="trio-item">
                <p className="project-bold-text">Development Platform</p>
                <p className="project-subtext">Unity</p>
              </div>
              <div className="trio-item">
                <p className="project-bold-text">Number of Scripts</p>
                <p className="project-subtext">50</p>
              </div>
            </div>
            <div className="trio-container">
              <div className="trio-item">
                <p className="project-bold-text">In-App Purchase</p>
                <p className="project-subtext">Yes</p>
              </div>
              <div className="trio-item">
                <p className="project-bold-text">Consoles</p>
                <p className="project-subtext">iPhone & iPad</p>
              </div>
              <div className="trio-item">
                <p className="project-bold-text">Other Tools Used</p>
                <p className="project-subtext">UI Toolkit</p>
              </div>
            </div>
            <div>
              <img className="project-image-nobordered" src={unity} alt=""></img>
            </div>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Level Design</p>

            <div className="icon-bullet">
              <img className="bullet-icon" src={brain_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Tutorial Integration</p>
                <p className="project-subtext">The tutorial is seamlessly integrated into the gameplay, enabling players to acquire fundamental controls while maintaining their immersion.</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={steps_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Gradual Introduction of New Mechanics</p>
                <p className="project-subtext">I systematically introduced new gameplay mechanics that gradually increased the complexity of levels.</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={puzzle_icon} alt=""></img>
              <div>
                <p className="project-bold-text">16 Puzzling Levels</p>
                <p className="project-subtext">By transforming sketches into code, I meticulously crafted 16 levels that challenge the intellect and captivate the visual senses.</p>
              </div>
            </div>
            <img className="project-image-nobordered" src={sketch} alt=""></img>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Tutorial Design</p>
            <p className="project-paragraph">Play testing revealed the necessity of an additional tooltip to help the player understand the bomb detonation mechanic.</p>
            <img className="project-image-nobordered" src={bomb} alt=""></img>
            <div className="icon-bullet">
              <img className="bullet-icon" src={warning_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Challenge</p>
                <p className="project-subtext">The player encountered difficulty in utilizing the bomb weapon, which necessitated an additional touch compared to the hammer and gun.</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={check_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Solution</p>
                <p className="project-subtext">To clarify the detonation process, I employed a tooltip that manifests upon the player's initial interaction with the bomb. This tooltip explicitly indicates that a second touch was necessary to detonate the bomb.</p>
              </div>
            </div>

          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Screen Architecture</p>
            <p className="project-paragraph">I used a flowchart to illustrate how screens are connected, what options are available on each screen, and the players' purchasing flow.</p>
            <img className="project-image-bordered" src={screenflow} alt=""></img>
            <p className="project-paragraph">Large yellow buttons guide the player to their next level and records their progress. Eventually, they direct the player to the Levels screen, where an In-App Purchase can be made to unlock the complete game.</p>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">UI Style Guide</p>
            <p className="project-paragraph">I designed the user interface assets using Figma, and the corresponding code was implemented within Unity's UI toolkit.</p>
            <img className="project-image-nobordered" src={style_guide} alt=""></img>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Purchasing More Levels</p>
            <p className="project-paragraph">Players are directed to the Level Select screen, where they can view a scrolling list of all levels in the game. By making an In-App Purchase, they can unlock all locked levels.</p>
            <img className="project-image-nobordered" src={purchase} alt=""></img>
            <p className="project-paragraph">I designed a prominent call-to-action in blue, which aligns with the blue lock icons that appear next to each locked level. Upon clicking the button, the iOS StoreKit UI is launched.</p>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">In-Game Weapons</p>
            <div className="icon-bullet">
              <img className="bullet-icon" src={hammer_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Hammer</p>
                <p className="project-subtext">The hammer is a strong weapon capable of shattering boxes, demolishing walls, and push enemies and obstacles that impede your progress.</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={gun_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Gun</p>
                <p className="project-subtext">The gun possesses an extended range for triggering explosions, but is weak against enemies' hard metal exterior.</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={bomb_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Bomb</p>
                <p className="project-subtext">The bomb possesses a wide range of applications, capable of generating explosions and providing cover to hide from enemies.</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={teleporter_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Teleporter</p>
                <p className="project-subtext">The teleporter has a long range for warping past obstacles and evading enemy detection.</p>
              </div>
            </div>
          </div>

          <div className="project-chunk">
            <p className="project-text-medium">Sprite Artwork</p>
            <p className="project-paragraph">Figma facilitated the rapid creation of each sprite and enabled the visualization of all elements on a single page.</p>
            <div className="trio-container">
              <div className="trio-item">
                <p className="project-bold-text">Tools Used</p>
                <p className="project-subtext">Figma</p>
              </div>
              <div className="trio-item">
                <p className="project-bold-text">Sprite Count</p>
                <p className="project-subtext">320</p>
              </div>
              <div className="trio-item">
                <p className="project-bold-text">Art Style</p>
                <p className="project-subtext">Cute Cartoon</p>
              </div>
            </div>            
            <img className="project-image-nobordered" src={sprites} alt=""></img>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Pause Screen</p>
            <p className="project-paragraph">An overlay appears on the screen when the pause button is pressed, providing control over the audio and options to resume play, restart a level, skip a level, and return to the main menu.</p>
            <img className="project-image-nobordered" src={pause} alt=""></img>
            <div className="icon-bullet">
              <img className="bullet-icon" src={warning_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Challenge</p>
                <p className="project-subtext">Conveying all the available options on a single screen while ensuring a consistent user interface across the entire app presented a significant challenge.</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={check_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Solution</p>
                <p className="project-subtext">After identifying the primary, secondary, and tertiary actions the player can take, it was straightforward to adhere to the UI style guide to assign colors, positioning, and sizing to the buttons.</p>
              </div>
            </div>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Funneling the Player to Purchase the Full Game</p>
            <div className="icon-bullet">
              <img className="bullet-icon" src={warning_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Challenge</p>
                <p className="project-subtext">I sought to ensure that players effortlessly experienced the entirety of the game's enjoyment while guiding them towards purchasing the complete game, thereby unlocking all levels.</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={check_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Solution</p>
                <p className="project-subtext">The player effortlessly completes the initial four levels consecutively. However, upon playing the 5th level, they are redirected to the Level Select screen. From this screen, players understand that the Purchase button unlocks the remaining levels.</p>
              </div>
            </div>
          </div>

          <div className="project-chunk">
            <p className="project-text-medium">Marketing Images</p>
            <p className="project-paragraph">Lastly, I created images for prospective players to preview the game on the App Store.</p>            
            <img className="project-image-nobordered" src={marketing} alt=""></img>
          </div>
  
        </div>

        <div className="header-container-repeat">
          <img className="header-profile" src={author} alt=""></img>
          <p className="header-text">Brandon Falcone</p>
          <p className="subheader-text">UX Designer, Developer</p>
        </div>

        

        <Footer></Footer>

      </div>
    );
  }
}

export default ProjectBajookieland;
