import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import '../App.css';

import author from '../Images/author.png';

import back_icon from '../Images/back-icon.svg';
import warning_icon from '../Images/warning-icon.svg';
import check_icon from '../Images/check-icon.svg';
import code_icon from '../Images/code-icon.svg';
import pencil_icon from '../Images/pencil-icon.svg';
import arrow_icon from '../Images/arrow-icon.svg';
import cards_icon from '../Images/cards-icon.svg';
import shopping_icon from '../Images/shopping-icon.svg';
import qr_icon from '../Images/qr-icon.svg';
import sparkle_icon from '../Images/sparkle-icon.svg';

import Footer from './Footer.js';

import pos1 from '../Images/pos-pro-1.jpg';
import pos2 from '../Images/pos-pro-2.jpg';
import pos3 from '../Images/pos-pro-3.jpg';
import pos4 from '../Images/pos-pro-4.jpg';
import pos5 from '../Images/pos-pro-5.jpg';
import pos6 from '../Images/pos-pro-6.jpg';
import pos7 from '../Images/pos-pro-7.jpg';
import pos8 from '../Images/pos-pro-8.jpg';
import pos9 from '../Images/pos-pro-9.jpg';
import pos10 from '../Images/pos-pro-10.jpg';
import pos11 from '../Images/pos-pro-11.jpg';
import pos12 from '../Images/pos-pro-12.jpg';
import pos13 from '../Images/pos-pro-13.jpg';
import pos14 from '../Images/pos-pro-14.jpg';
import pos15 from '../Images/pos-pro-15.jpg';
import pos16 from '../Images/pos-pro-16.jpg';
import pos17 from '../Images/pos-pro-17.jpg';
import pos18 from '../Images/pos-pro-18.jpg';
import pos19 from '../Images/pos-pro-19.jpg';
import pos20 from '../Images/pos-pro-20.jpg';
import pos21 from '../Images/pos-pro-21.jpg';
import pos22 from '../Images/pos-pro-22.jpg';
import pos23 from '../Images/pos-pro-23.jpg';


class ProjectTillster extends Component {
  
    constructor(props) {
        super();
        this.props = props;
    }
  
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount(){

  }

  visitWebsite() {
    //window.location.href = "";
  }

  render () {
    
    const footerYear = new Date().getUTCFullYear();

    return (
        
      <div id="project">
        
        <div className="nav">
          <Link to="/" class="link"><div className="back-container">
            <img className="back-icon" src={back_icon} alt=""></img>
            <p className="back-text">Back</p>
          </div></Link>
          <p className="nav-title"><Link to="/" class="link-nav-title">Brandon Falcone</Link></p>
          <Link class="link-disabled" style={{opacity:"0"}} ><div className="back-container">
            <img className="back-icon" src={back_icon} alt=""></img>
            <p className="back-text">Back</p>
          </div></Link>
        </div>
        <img className="project-image" src={pos19} alt=""></img>

        <div className="project-content">
          <p className="project-title-large">POS Visualizer: Data Visualization Tool for Fast Food Chains</p>
          <div className="button-container">
            <p className="project-bold-text">Demo the website:</p>
            <div className="button" onClick={(e) => {this.visitWebsite()}}>
              <p>POS Visualizer Demo</p>
              <img className="button-icon" src={arrow_icon} alt=""></img>
            </div>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">My Tasks</p>

            <div className="icon-bullet">
              <img className="bullet-icon" src={pencil_icon} alt=""></img>
              <div>
                <p className="project-bold-text">UI/UX Design</p>
                <p className="project-subtext">I designed the user interface as well as the user experience.</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={code_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Angular Programming</p>
                <p className="project-subtext">I developed the front-end and back-end of the application using Angular.</p>
              </div>
            </div>
          </div>

          <div className="project-chunk">
            <p className="project-text-medium">App Overview</p>
            <p className="project-paragraph">POS Visualizer is an Angular app created by me for Tillster. The purpose is to transform data-heavy POS files within the restaurant industry into a more user-friendly and comprehensible format.</p>
            <div>
              <img className="project-image-bordered" src={pos17} alt=""></img>
            </div>
            <p className="project-paragraph">The tool assists in the engineering of kiosk ordering software for restaurants such as Burger King, Popeyes, and other fast food chains.</p>
            <p className="project-bold-text">Burger King's Ordering Kiosk:</p>
            <div>
              <img className="project-image-bordered" src={pos14} alt=""></img>
            </div>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Problem & Solution</p>
            <div className="icon-bullet">
              <img className="bullet-icon" src={warning_icon} alt=""></img>
              <div>
                <p className="project-bold-text">The Problem</p>
                <p className="project-subtext">The current method of viewing XML and JSON files in a text editor lacks efficiency and user-friendliness. Notice the difficulty in reading a typical unformatted JSON file below.</p>
              </div>
            </div>
            <img className="project-image-bordered" src={pos4} alt=""></img>
            <div className="icon-bullet">
              <img className="bullet-icon" src={check_icon} alt=""></img>
              <div>
                <p className="project-bold-text">The Solution</p>
                <p className="project-subtext">The tool aims to address this issue by creating a more human-readable and interactive display of these files.</p>
              </div>
            </div>
            <img className="project-image-bordered" src={pos20} alt=""></img>   
          </div>


          <div className="project-chunk">
            <p className="project-text-medium">Enhancing the User Experience</p>
            <p className="project-paragraph">The application streamlines the number of steps needed for an engineer to access and view POS files. Consequently, users can remain within their browser, eliminating the need for a text editor to view these files.</p>
            <p className="project-bold-text">Current Workflow:</p>
            <div>
              <img className="project-image-nobordered" src={pos6} alt=""></img>
            </div>
            <p className="project-bold-text">New Workflow:</p>
            <div>
              <img className="project-image-nobordered" src={pos7} alt=""></img>
            </div>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Business Impact</p>
            <div className="icon-bullet">
              <img className="bullet-icon" src={check_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Enhanced search capabilities</p>
                <p className="project-paragraph">Time to search data has substantially improved.</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={check_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Reduced task completion time</p>
                <p className="project-paragraph">Users were able to complete more tasks in a shorter time.</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={check_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Improved readability of POS files</p>
                <p className="project-paragraph">Enhanced data visualization improved user knowledge and confidence.</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={check_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Increased user satisfaction</p>
                <p className="project-paragraph">Users enjoyed a tool that made their work easier.</p>
              </div>
            </div>
          </div>

          <div className="project-chunk">
            <p className="project-text-medium">Integrating the Tool</p>
            <p className="project-paragraph">I redesigned the Kiosk Tools page to enhance the accessibility of POS Visualizer and other tools.</p>
            <img className="project-image-bordered" src={pos16} alt=""></img>
            <p className="project-paragraph">The accompanying image illustrates what the page looked like prior to my redesign.</p>
            <img className="project-image-bordered" src={pos18} alt=""></img>
          </div>

          <div className="project-chunk">
            <p className="project-text-medium">Enhancements in Angular</p>
            <p className="project-paragraph">I enhanced the UX by implementing the Javascript fetch() function that could automatically get files from URLs. This is an improvement over the original design that required the user to copy and paste file contents into a text field.</p>
            <div>
              <img className="project-image-nobordered" src={pos21} alt=""></img>
            </div>
            <p className="project-paragraph">Notice the complexities of the original design that required the user to take extra steps to copy and paste file contents.</p>
            <div>
              <img className="project-image-nobordered" src={pos23} alt=""></img>
            </div>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Solving Efficiency Concerns</p>
            <div className="icon-bullet">
              <img className="bullet-icon" src={warning_icon} alt=""></img>
              <div>
                <p className="project-bold-text">The Challenge</p>
                <p className="project-subtext">The current approach to load the entirety of a JSON/XML file impacted load speed and the capacities of the browser's memory.</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={check_icon} alt=""></img>
              <div>
                <p className="project-bold-text">The Solution</p>
                <p className="project-subtext">To solve this issue, it was possible to load only 5% of the file, allowing the user to see the names and IDs of elements in the file. In an accordion-style design, the user could click to load all data for an element, eliminating the need to load 95% of the file's contents.</p>
              </div>
            </div>
            <img className="project-image-nobordered" src={pos22} alt=""></img>   
          </div>

          <div className="project-chunk">
            <p className="project-text-medium">Navigation Design</p>
            <p className="project-paragraph">3 dropdowns are used to navigate to over 30+ webpages. The dropdown items are dynamically populated to provide users with relevant options as they progress from Dropdown 1 to Dropdown 3.</p>
            <div>
              <img className="project-image-nobordered" src={pos11} alt=""></img>
            </div>
            <p className="project-paragraph">The number of items displayed in each dropdown, as outlined below, facilitated the decision to use it for navigation purposes.</p>
            <div className="trio-container">
              <div className="trio-item">
                <p className="project-bold-text">Minimum</p>
                <p className="project-subtext">1 item</p>
              </div>
              <div className="trio-item">
                <p className="project-bold-text">Maximum</p>
                <p className="project-subtext">16 items</p>
              </div>
              <div className="trio-item">
                <p className="project-bold-text">Average</p>
                <p className="project-subtext">4 items</p>
              </div>
            </div>
          </div>

          <div className="project-chunk">
            <p className="project-text-medium">File Viewer Design</p>
            <p className="project-paragraph">The file viewer area of the screen displays content that overflows to the bottom, providing an affordance of vertical scrolling.</p>
            <div>
              <img className="project-image-nobordered" src={pos13} alt=""></img>
            </div>
          </div>

          <div className="project-chunk">
            <p className="project-text-medium">Contextual Navigation Design</p>
            <p className="project-paragraph">The golden ratio was employed to split pages with responsive widths, resulting in a ratio of 1.618. This design effectively draws the user's attention to the prominantly colored call to action button.</p>
            <div>
              <img className="project-image-bordered" src={pos12} alt=""></img>
            </div>
          </div>

          <div className="project-chunk">
            <p className="project-text-medium">Visualizing Data</p>
            <p className="project-paragraph">The accompanying images illustrate a diverse range of formulas employed for the presentation of XML and JSON data.</p>
            <div>
              <img className="project-image-bordered" src={pos2} alt=""></img>
            </div>
            <div>
              <img className="project-image-bordered" src={pos3} alt=""></img>
            </div>
            <div>
              <img className="project-image-bordered" src={pos8} alt=""></img>
            </div>
            <div>
              <img className="project-image-bordered" src={pos9} alt=""></img>
            </div>
          </div>

  
        </div>

        <div className="header-container-repeat">
          <img className="header-profile" src={author} alt=""></img>
          <p className="header-text">Brandon Falcone</p>
          <p className="subheader-text">UX Designer, Developer</p>
        </div>

        <Footer></Footer>
        
      </div>
    );
  }
}

export default ProjectTillster;
