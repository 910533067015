import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import '../App.css';

import author from '../Images/author.png';

import back_icon from '../Images/back-icon.svg';
import user_icon from '../Images/user-icon.svg';
import code_icon from '../Images/code-icon.svg';
import pencil_icon from '../Images/pencil-icon.svg';
import arrow_icon from '../Images/arrow-icon.svg';
import cards_icon from '../Images/cards-icon.svg';
import shopping_icon from '../Images/shopping-icon.svg';
import qr_icon from '../Images/qr-icon.svg';
import sparkle_icon from '../Images/sparkle-icon.svg';

import Footer from './Footer.js';

import projectad from '../Images/project-ad.jpg';
import ad1 from '../Images/ad1.jpg';
import ad2 from '../Images/ad2.jpg';
import ad3 from '../Images/ad3.jpg';
import ad4 from '../Images/ad4.jpg';
import ad6 from '../Images/ad6.jpg';
import ad11 from '../Images/ad11.jpg';
import ad15 from '../Images/ad15.jpg';
import ad16 from '../Images/ad16.jpg';

class ProjectAdditiveDrinking extends Component {
  
    constructor(props) {
        super();
        this.props = props;
    }
  
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount(){

  }

  visitWebsite() {
    window.location.href = "https://additivedrinking.com";
  }

  render () {
    
    const footerYear = new Date().getUTCFullYear();

    return (
        
      <div id="project">
        
        <div className="nav">
          <Link to="/" class="link"><div className="back-container">
            <img className="back-icon" src={back_icon} alt=""></img>
            <p className="back-text">Back</p>
          </div></Link>
          <p className="nav-title"><Link to="/" class="link-nav-title">Brandon Falcone</Link></p>
          <Link class="link-disabled" style={{opacity:"0"}} ><div className="back-container">
            <img className="back-icon" src={back_icon} alt=""></img>
            <p className="back-text">Back</p>
          </div></Link>
        </div>
        <img className="project-image" src={projectad} alt=""></img>

        <div className="project-content">
          <p className="project-title-large">Additive Drinking: A Card Game and E-Commerce Website</p>
          <div className="button-container">
            <p className="project-bold-text">Visit the website:</p>
            <div className="button" onClick={(e) => {this.visitWebsite()}}>
              <p>AdditiveDrinking.com</p>
              <img className="button-icon" src={arrow_icon} alt=""></img>
            </div>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">My Tasks</p>

            <div className="icon-bullet">
              <img className="bullet-icon" src={cards_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Game Creation</p>
                <p className="project-subtext">Created an original card game that successfully launched on Kickstarter</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={code_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Programming</p>
                <p className="project-subtext">Developed an e-commerce website integrating the PayPal API to handle transactions</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={pencil_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Website UX</p>
                <p className="project-subtext">Wireframed and architected the website navigation and purchasing flow to funnel potential customers</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={user_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Play Testing</p>
                <p className="project-subtext">Conducted play tests to gather feedback, optimizing gameplay, and improving the card design</p>
              </div>
            </div>
          </div>

          <div className="project-chunk">
            <p className="project-text-medium">The Product</p>
            <p className="project-paragraph">The card game doubles as a drinking game that will challenge your friends' drinking abilities in competitive fun.</p>
            <div className="trio-container">
              <div className="trio-item">
                <p className="project-bold-text">Category</p>
                <p className="project-subtext">Card Game</p>
              </div>
              <div className="trio-item">
                <p className="project-bold-text">Number of Cards</p>
                <p className="project-subtext">61</p>
              </div>
              <div className="trio-item">
                <p className="project-bold-text">Age Restriction</p>
                <p className="project-subtext">21+</p>
              </div>
            </div>
            <div>
              <img className="project-image-nobordered" src={ad2} alt=""></img>
            </div>
          </div>

          <div className="project-chunk">
            <p className="project-text-medium">The Kickstarter</p>
            <p className="project-paragraph">The Kickstarter campaign launched in October 2020 with a funding goal of $500. In November, the goal was reached and I supplied a professionally packaged game to all Kickstarter backers.</p>
            <div className="trio-container">
              <div className="trio-item">
                <p className="project-bold-text">Delivery Date</p>
                <p className="project-subtext">Dec 2020</p>
              </div>
              <div className="trio-item">
                <p className="project-bold-text">Funding Goal</p>
                <p className="project-subtext">$500</p>
              </div>
              <div className="trio-item">
                <p className="project-bold-text">Backers</p>
                <p className="project-subtext">24</p>
              </div>
            </div>
            <div>
              <img className="project-image-bordered" src={ad1} alt=""></img>
            </div>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Programming the Website</p>
            <p className="project-paragraph">I designed and developed a website in React to handle the following functionality:</p>
            <div className="icon-bullet">
              <img className="bullet-icon" src={shopping_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Purchasing Capabilities</p>
                <p className="project-subtext">The PayPal API allows users to purchase the game online using their credit card or PayPal account.</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={qr_icon} alt=""></img>
              <div>
                <p className="project-bold-text">The Game's Instructions</p>
                <p className="project-subtext">A QR Code included with the game links the player to the website where they can read the game's instructions</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={sparkle_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Promotional Content</p>
                <p className="project-subtext">The homepage serves to funnel users to purchase the game using </p>
              </div>
            </div>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Checkout Experience</p>
            <p className="project-paragraph">Users are funneled to purchase the game using uniquely designed CTAs on the homepage where they can pay using a credit card or PayPal.</p>
            <img className="project-image-nobordered" src={ad15} alt=""></img>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Contents of the Game</p>
            <p className="project-paragraph">The game includes 61 playing cards and 1 instructional QR card linking to the game's instructions.</p>
            <img className="project-image-nobordered" src={ad4} alt=""></img>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">The QR Code Experience</p>
            <p className="project-paragraph">I consolidated the game's instructions into a playing card that fits nicely in the box with the other cards. This reduced the need to print extra paper and is less hassle for the player to deal with. Scanning the code takes you to the website for a more personal viewing of the instructions.</p>
            <img className="project-image-nobordered" src={ad16} alt=""></img>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Card Design</p>
            <p className="project-paragraph">Beer cards have a drinking task and a point value corresponding to the tasks' difficulty level. The background art depicts a rising quantity of beer such that tasks that require more drinking have more of the beer artwork. </p>
            <img className="project-image-nobordered" src={ad6} alt=""></img>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">3D Marketing Media</p>
            <p className="project-paragraph">I used Blender to create 3D models and animations to use in marketing media. The scenes below capture a simulation of the playing and drinking experience.</p>
            <img className="project-image-nobordered" src={ad3} alt=""></img>
            <img className="project-image-nobordered" src={ad11} alt=""></img>
          </div>
  
        </div>

        <div className="header-container-repeat">
          <img className="header-profile" src={author} alt=""></img>
          <p className="header-text">Brandon Falcone</p>
          <p className="subheader-text">UX Designer, Developer</p>
        </div>

        <Footer></Footer>
        
      </div>
    );
  }
}

export default ProjectAdditiveDrinking;
