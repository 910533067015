import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import '../App.css';

import author from '../Images/author.png';

import back_icon from '../Images/back-icon.svg';
import user_icon from '../Images/user-icon.svg';
import code_icon from '../Images/code-icon.svg';
import pencil_icon from '../Images/pencil-icon.svg';
import warning_icon from '../Images/warning-icon.svg';
import check_icon from '../Images/check-icon.svg';
import arrow_icon from '../Images/arrow-icon.svg';
import sparkle_icon from '../Images/sparkle-icon.svg';
import present_icon from '../Images/present-icon.svg';
import diagram_icon from '../Images/diagram-icon.svg';
import book_icon from '../Images/book-icon.svg';

import Footer from './Footer.js';

import mydashboard from '../Images/my-dashboard.jpg';
import mysitemap from '../Images/my-sitemap.jpg';
import apsitemap from '../Images/ap-sitemap.jpg';
import apflowchart from '../Images/ap-flowchart.jpg';
import apdashboard from '../Images/ap-dashboard.jpg';
import apmultistep from '../Images/ap-multistep.jpg';
import apuserzoom from '../Images/ap-userzoom.jpg';
import apscreens from '../Images/ap-screens.jpg';
import apstyleguide from '../Images/ap-styleguide.jpg'
import mybefore from '../Images/my-before.jpg'
import laptop from '../Images/laptop-myresource.jpg'
import myquicklinks from '../Images/my-quick-links.jpg'
import mybanner from '../Images/my-banner.jpg'
import myclaim from '../Images/my-claim.jpg'

class ProjectPortals extends Component {
  
    constructor(props) {
        super();
        this.props = props;
    }
  
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount(){

  }

  visitWebsite() {
    window.location.href = "https://agentportal.icwgroup.com";
  }

  render () {
    
    const footerYear = new Date().getUTCFullYear();

    return (
        
      <div id="project">
        
        <div className="nav">
          <Link to="/" class="link"><div className="back-container">
            <img className="back-icon" src={back_icon} alt=""></img>
            <p className="back-text">Back</p>
          </div></Link>
          <p className="nav-title"><Link to="/" class="link-nav-title">Brandon Falcone</Link></p>
          <Link class="link-disabled" style={{opacity:"0"}} ><div className="back-container">
            <img className="back-icon" src={back_icon} alt=""></img>
            <p className="back-text">Back</p>
          </div></Link>
        </div>
        <img className="project-image" src={laptop} alt=""></img>

        <div className="project-content">
          <p className="project-title-large">Insurance Company Portals: For Policyholders and Agents</p>
          <div className="button-container">
            <p className="project-bold-text">Visit the website:</p>
            <div className="button" onClick={(e) => {this.visitWebsite()}}>
              <p>agentportal.icwgroup.com</p>
              <img className="button-icon" src={arrow_icon} alt=""></img>
            </div>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">My Tasks</p>

            <div className="icon-bullet">
              <img className="bullet-icon" src={diagram_icon} alt=""></img>
              <div>
                <p className="project-bold-text">UX Design</p>
                <p className="project-subtext">I designed wireframes and mockups for 2 new customer-facing portals.</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={code_icon} alt=""></img>
              <div>
                <p className="project-bold-text">UI Engineering</p>
                <p className="project-subtext">I developed the front-end using HTML, CSS, Java, JS, and the Vaadin framework.</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={present_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Presented Design Ideas </p>
                <p className="project-subtext">I communicated design concepts to stakeholders, managers, graphic designers, and back-end developers.</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={user_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Usability Testing</p>
                <p className="project-subtext">I conducted usability tests with actual users to pinpoint issues and refine the user experience.</p>
              </div>
            </div>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Onboarding New Users</p>
            <p className="project-paragraph">I designed a multi-step form that guides new users through the onboarding process, breaking down a complex  process into smaller, manageable steps to avoid overwhelming the user with excessive information.</p>
            <img className="project-image-nobordered" src={apmultistep} alt=""></img>
            <p className="project-paragraph">Flowcharts were employed to communicate the user flow to stakeholders within the company, facilitating effective discussions.</p>
            <img className="project-image-nobordered" src={apflowchart} alt=""></img>
          </div>

          <div className="project-chunk">
            <p className="project-text-medium">Sitemap</p>
            <p className="project-paragraph">I organized the website's content into categories using a sitemap, including Billing, Claims, Free Services, and Account.</p>
            <div>
              <img className="project-image-bordered" src={mysitemap} alt=""></img>
            </div>
            <p className="project-paragraph">The second portal includes enhanced account management necessitating the division of this group into smaller subcategories, and the creation of a dedicated section for the Administrator audience.</p>
            <div>
              <img className="project-image-bordered" src={apsitemap} alt=""></img>
            </div>
          </div>

          <div className="project-chunk">
            <p className="project-text-medium">Quick Links</p>
            <p className="project-paragraph">Policyholders are now accessing the portal to pay their bills. To enhance their user experience, I have strategically placed a prominent quick-link section on the dashboard, ensuring direct access to their bills. </p>
            <div>
              <img className="project-image-bordered" src={myquicklinks} alt=""></img>
            </div>
            <p className="project-paragraph">Cards, characterized by their large size and clickability, are designed with specific signifiers to enhance user experience. These elements include blue text color, box shadow, arrow icon, hover animation, and pointer cursor. These seemingly small yet crucial design elements significantly contribute to an enjoyable user experience on the web.</p>
          </div>

          <div className="project-chunk">
            <p className="project-text-medium">Promotional Content Design</p>
            <p className="project-paragraph">A banner at the top of the dashboard serves to promote new features or blog posts. </p>
            <div>
              <img className="project-image-bordered" src={mybanner} alt=""></img>
            </div>
            <p className="project-paragraph">A clear call-to-action and content hierarchy prioritize more significant content over less important content. Pagination indicators and arrows provide an intuitive navigation experience for a limited number of elements. </p>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">UI Style Guide</p>
            <p className="project-paragraph">I crafted a style guide utilizing components from the Vaadin component library to ensure a cohesive visual identity across company websites.</p>
            <div className="icon-bullet">
              <img className="bullet-icon" src={sparkle_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Consistent Brand Identity</p>
                <p className="project-subtext">By leveraging an existing brand document, I developed the company's initial user interface style guide.</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={book_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Vaadin Components</p>
                <p className="project-subtext">I designed and styled components in the Vaadin component library that I coded in the development phase.</p>
              </div>
            </div>
            <img className="project-image-nobordered" src={apstyleguide} alt=""></img>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Dashboard Design</p>
            <p className="project-paragraph">Upon logging, the insurance agent is presented with a feed of recent activities since their last login. To facilitate further exploration of their feed, the Vaadin dialog component has been utilized as an overlay, thereby minimizing the time spent navigating between the feed and the detailed information.</p>
            <img className="project-image-nobordered" src={apdashboard} alt=""></img>
            <div className="icon-bullet">
              <img className="bullet-icon" src={warning_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Challenge</p>
                <p className="project-subtext">It was a challenge ensuring the users' activity feed was not overwhelmed with excessive information that would cause discomfort.</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={check_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Solution</p>
                <p className="project-subtext">To enhance the user experience, I identified the 3 most crucial pieces of data for each new activity and organized them into a content hierarchy. This hierarchy was achieved by varying the size, color, and spacing of text elements to ensure the information was digestible.</p>
              </div>
            </div>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">My Deliverables</p>
            <p className="project-paragraph">I collaborated with my team of developers, graphic designers, and managers to deliver the following:</p>
            <div className="icon-bullet">
              <img className="bullet-icon" src={diagram_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Diagrams</p>
                <p className="project-subtext">During the architecture phase, I provided sitemaps and flowcharts.</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={pencil_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Wireframes and Prototypes</p>
                <p className="project-subtext">During the design phase, I provided wireframes and interactive prototypes constructed using Figma.</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={code_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Code</p>
                <p className="project-subtext">During the development phase, I programmed the front-end of the website using Java, HTML, CSS, and JS.</p>
              </div>
            </div>
          </div>

          

          <div className="icon-bullets">
            <p className="project-text-medium">Designing and Coding</p>
            <p className="project-paragraph">I designed and implemented another comprehensive portal for a substantial portion of our customer base, enhancing its functionality, increasing the number of screens, and facilitating seamless collaboration among team members.</p>
            <div className="trio-container">
              <div className="trio-item">
                <p className="project-bold-text">My Role</p>
                <p className="project-subtext">UI/UX Engineer</p>
              </div>
              <div className="trio-item">
                <p className="project-bold-text">Design Tool</p>
                <p className="project-subtext">Figma</p>
              </div>
              <div className="trio-item">
                <p className="project-bold-text">Programming</p>
                <p className="project-subtext">Java, Vaadin, HTML/CSS</p>
              </div>
            </div><img className="project-image-nobordered" src={apscreens} alt=""></img>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Usability Testing</p>
            <p className="project-paragraph">In one hour sessions conducted via User Zoom Go, I observed real customers completing tasks using a high-fidelity prototype. During these sessions, I recorded user pain points, prioritized findings, and iterated the designs based on user feedback.</p>
            <img className="project-image-bordered" src={apuserzoom} alt=""></img>
            <div className="icon-bullet">
              <img className="bullet-icon" src={warning_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Challenge</p>
                <p className="project-subtext">Policyholders anticipate the ability to report a claim upon logging in, however, this functionality is currently only available on a separate website.</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={check_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Solution</p>
                <p className="project-subtext">It was logical to grant users access to this feature by means of a clickable card on the dashboard directing them to a separate website. </p>
              </div>
            </div>
            <img className="project-image-nobordered" src={myclaim} alt=""></img>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Before and After</p>
            <p className="project-paragraph">The accompanying images illustrate the per- and post- work redesign and interface development of the dashboard for policyholders, aiming to enhance the overall user experience.</p>
            <p className="project-bold-text">Before:</p>
            <img className="project-image-nobordered" src={mybefore} alt=""></img>
            <p className="project-bold-text">After:</p>
            <img className="project-image-nobordered" src={mydashboard} alt=""></img>
          </div>
  
        </div>

        <div className="header-container-repeat">
          <img className="header-profile" src={author} alt=""></img>
          <p className="header-text">Brandon Falcone</p>
          <p className="subheader-text">UX Designer, Developer</p>
        </div>

        <Footer></Footer>
        
      </div>
    );
  }
}

export default ProjectPortals;
